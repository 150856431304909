import React, { Fragment, useState, useEffect, useContext } from "react";
import Input from "../components/yourdetails/inputs/Input";
import { spaAPI } from "../shared/apis/spaAPI";
import { InputDataContext } from "../shared/contexts/InputDataContext";
import { useScrollPosition } from "../shared/utilities/useScrollPosition";
import PartTimeServiceInput from "../components/yourdetails/inputs/PartTimeServiceInput";
import CalculateButton from "../components/yourdetails/CalculateButton";
import MainTextModal from "../components/general/VideoModalMainText";
import DetailsVideo from "../assets/videos/RBI 3 MemberInfo 210923.mp4";
import params from "../shared/utilities/global_parameters";

import classes from "./2-YourDetails.module.css";

function YourDetailsPage() {
  const { inputs, setInputs, valid, setValid } = useContext(InputDataContext);
  const [onPage, setOnPage] = useState(false);
  const premiumCutOff = new Date("2002-10-01");
  const today = new Date();
  const birthday = new Date(inputs.dateOfBirth);
  const age = today - birthday;
  const ageNextBirthday = Math.floor(age / 31557600000) + 1;
  const retAgeLower = Math.min(66, Math.max(55, ageNextBirthday));
  let earliestDoB = today.setDate(today.getDate() - 1);
  earliestDoB = new Date(earliestDoB).setFullYear(
    new Date(earliestDoB).getFullYear() - 66
  );

  const scrollPosition = useScrollPosition();
  const [isScrolledDown, setIsScrolledDown] = useState(false);
  const [showModal, setShowModal] = useState(true);
  useEffect(() => {
    if (scrollPosition > 90) {
      setIsScrolledDown(true);
    } else {
      setIsScrolledDown(false);
    }
  }, [scrollPosition]);

  let latestDoB = "1999-12-31";
  useEffect(() => {
    if (inputs.dateOfJoining) {
      latestDoB = new Date(inputs.dateOfJoining).setFullYear(
        new Date(inputs.dateOfJoining).getFullYear() - 16
      );
    } else {
      latestDoB = "1999-12-31";
    }
  }, [inputs.dateOfJoining]);

  useEffect(() => {
    spaAPI(new Date(inputs.dateOfBirth), inputs, setInputs);
  }, [inputs.dateOfBirth]);

  useEffect(() => {
    if (onPage) {
      if (inputs.hasLeft === "No") {
        setInputs({
          ...inputs,
          dateLeftScheme: null,
        });
        setValid({ ...valid, dateLeftScheme: true });
      }
      if (inputs.hasLeft === "Yes") {
        setInputs({
          ...inputs,
          dateLeftScheme: null,
          legacyScheme: null,
          dateOfBirth: null,
          spa: null,
          dateOfJoining: null,
          salaryCurrent: null,
          salary2015: null,
          retirementAge: null,
          hasPT: null,
          partTimeService: [],
          salaryIncrease: 0,
        });
        setValid({
          ...valid,
          dateLeftScheme: false,
          legacyScheme: false,
          dateOfBirth: false,
          dateOfJoining: false,
          salaryCurrent: false,
          salary2015: false,
          retirementAge: false,
          hasPT: false,
        });
      }
    }
  }, [inputs.hasLeft]);

  useEffect(() => {
    setOnPage(true);
  }, []);

  return (
    <div id="Your_Details_Page">
      {showModal && <div style={{ height: "9.75rem" }}></div>}
      <MainTextModal
        videoTitle={"Details Page Guide"}
        video={DetailsVideo}
        at_top={!isScrolledDown}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <h2>Tell us about your career</h2>
      <p>
        Please answer the following questions to allow us to provide you with an
        estimate of your pension benefits.
      </p>
      <Input
        input={"inReceipt"}
        inputType={"select"}
        options={[{ value: "Yes" }, { value: "No" }]}
        text={`Are you currently in receipt of all or part of your Civil Service pension?`}
        help={null}
      />
      {inputs.inReceipt === "Yes" && (
        <div className={classes.input_container}>
          <p>
            As you have already retired or partially retired, your pension
            administrator will be in touch if you are impacted.
          </p>
        </div>
      )}
      {inputs.inReceipt === "No" ? (
        <Input
          input={"hasLeft"}
          inputType={"select"}
          options={[{ value: "Yes" }, { value: "No" }]}
          text={`Have you already left the Civil Service Pension Scheme?`}
          help={null}
        />
      ) : null}
      {valid.inReceipt && inputs.hasLeft === "Yes" && valid.hasLeft ? (
        <Input
          input={"dateLeftScheme"}
          inputType={"date"}
          text={`When did you leave the Civil Service Pension Scheme?`}
          help={null}
          minValue={"2015-04-01"}
          maxValue={new Date()}
          minValErrorMsg={
            "As the date you left the scheme is earlier than April 2015 you are not suitable for this illustrator."
          }
          maxValErrorMsg={
            "The date you left the scheme cannot be later than today's date."
          }
        />
      ) : null}
      {valid.inReceipt && valid.hasLeft && valid.dateLeftScheme ? (
        <Input
          input={"legacyScheme"}
          inputType={"select"}
          options={[
            { value: "classic" },
            { value: "classic plus" },
            { value: "premium" },
            { value: "nuvos" },
          ]}
          text={`Which Civil Service Pension Scheme were you a member of prior to 1st April ${params.remedy_start}?`}
          help={null}
        />
      ) : null}
      {valid.inReceipt &&
      valid.hasLeft &&
      valid.dateLeftScheme &&
      valid.legacyScheme ? (
        <Input
          input={"dateOfBirth"}
          inputType={"date"}
          text={"What is your date of birth?"}
          help={
            <Fragment>
              <h2>Date of Birth</h2>
              <p>
                This calculator can only be used by those who have not yet
                reached their State Pension Age; entering a date of birth
                corresponding to a State Pension date that has been reached in
                the past is not possible.
              </p>
            </Fragment>
          }
          minValue={new Date(earliestDoB)}
          maxValue={new Date(latestDoB)}
          minValErrorMsg={
            "As you are over your State Pension Age you are not suitable for this illustrator."
          }
          maxValErrorMsg={"The DoB date you've entered is too late."}
        />
      ) : null}

      {valid.inReceipt &&
      valid.hasLeft &&
      valid.dateLeftScheme &&
      valid.legacyScheme &&
      valid.dateOfBirth ? (
        <Input
          input={"dateOfJoining"}
          inputType={"date"}
          text={`What date did you join the Civil Service Pension Scheme?`}
          help={
            <Fragment>
              <h2>Date Joined Scheme</h2>
              <p>
                If you don't know the exact date when you joined the scheme,
                please provide the approximate date. If you have multiple
                periods of service because you left and then rejoined, please
                provide the earliest joining date, and in the question about
                part time working that will appear below on this page, enter any
                intervening periods during which you were not in service as part
                time periods with the part time proportion being zero. Classic
                members should have a joining date on or before 30 September
                2002; premium members should have a joining date between 01
                October 2002 and 30 July 2007.
              </p>
              <p>
                If you have transferred service into your classic, premium or
                classic plus scheme, you can allow for this by using a date of
                joining before your actual date joined scheme. For example, if
                you joined the classic scheme on 1 April 2000 but transferred in
                2 years of service then you can amend your date of joining to 1
                April 1998 to include this. Please note the illustrator does not
                provide for transfers into nuvos or alpha. For those with
                classic plus service, the illustrator will only add transferred
                in service to the classic proportion of your benefits.
              </p>
            </Fragment>
          }
          minValue={
            new Date(
              new Date(inputs.dateOfBirth).setFullYear(
                new Date(inputs.dateOfBirth).getFullYear() + 16
              )
            )
          }
          maxValue={"2012-03-31"}
          minValErrorMsg={
            "Your date of joining must be at least 16 years after your date of birth."
          }
          maxValErrorMsg={
            "Your date of joining must be before 1 April 2012 to be eligible for the McCloud Remedy."
          }
        />
      ) : null}
      {inputs.legacyScheme === "premium" &&
        new Date(inputs.dateOfJoining) <= premiumCutOff && (
          <div className={classes.input_container}>
            <p>
              If you are a premium member with a date of joining the scheme
              before October 2002, we will assume that you opted to convert your
              pre-October 2002 service into premium service. Your pre-October
              2002 service will be multiplied by 0.92 when calculating final
              salary benefits in line with regulations. In some cases, the
              factor used to convert your pre-October 2002 service into the
              premium scheme may differ slightly from 0.92 but this has not been
              allowed for.
            </p>
          </div>
        )}
      {valid.inReceipt &&
      valid.hasLeft &&
      valid.dateLeftScheme &&
      valid.legacyScheme &&
      valid.dateOfBirth &&
      valid.dateOfJoining ? (
        <Input
          input={"salaryCurrent"}
          inputType={"number"}
          text={"What is your most recent gross full time annual pay?"}
          help={
            <Fragment>
              <h2>Current Salary</h2>
              <p>
                This should be pensionable earnings and should be the Full-Time
                Equivalent amount. For example, if you are a part-time worker,
                this means that if your actual pay for part-time working is
                £15,000 per year and you work 20 hours per week of a 37 hour
                week, the Full-Time Equivalent would be £15,000 / 20 = £750.00 x
                37 = £27,750.
              </p>
              <p>
                Please note that final pensionable pay used in this illustrator
                is based on gross pay at the point of retirement which differs
                from the actual final pensionable pay definitions that will be
                used at retirement.
              </p>
            </Fragment>
          }
          minValue={0}
          maxValue={1000000}
          minValErrorMsg={"The current salary you've entered is too low."}
          maxValErrorMsg={"The current salary you've entered is too high."}
        />
      ) : null}
      {valid.inReceipt &&
      valid.hasLeft &&
      valid.dateLeftScheme &&
      valid.legacyScheme &&
      valid.dateOfBirth &&
      valid.dateOfJoining &&
      valid.salaryCurrent ? (
        <Input
          input={"salary2015"}
          inputType={"number"}
          text={
            <>
              {`What was your gross full time annual pay on 1 April ${params.remedy_start}?`}
              <br />
              (If unknown, please enter 0)
            </>
          }
          help={
            <Fragment>
              <h2>{params.remedy_start} Salary</h2>
              <p>
                This should be pensionable earnings and should be the Full-Time
                Equivalent amount. For example, if you were a part-time worker,
                this means that if your actual pay for part-time working was
                £15,000 per year and you worked 20 hours per week of a 37 hour
                week, the Full-Time Equivalent would be £15,000 / 20 = £750.00 x
                37 = £27,750.
              </p>
              <p>
                Please note that final pensionable pay used in this illustrator
                is based on gross pay at the point of retirement which differs
                from the actual final pensionable pay definitions that will be
                used at retirement.
              </p>
            </Fragment>
          }
          minValue={0}
          maxValue={1000000}
          minValErrorMsg={`The ${params.remedy_start} salary you've entered is too low.`}
          maxValErrorMsg={`The ${params.remedy_start} salary you've entered is too high.`}
        />
      ) : null}
      {valid.inReceipt &&
      valid.hasLeft &&
      valid.dateLeftScheme &&
      valid.legacyScheme &&
      valid.dateOfBirth &&
      valid.dateOfJoining &&
      valid.salaryCurrent &&
      valid.salary2015 ? (
        <Input
          input={"retirementAge"}
          inputType={"number"}
          text={"At what age do you plan to retire?"}
          help={
            <Fragment>
              <h2>Desired Retirement Age</h2>
              <p>
                This must be a whole number not greater than your State Pension
                Age and not earlier than the lesser of 55 and your age at your
                next birthday (or your current age if today is your birthday)
              </p>
            </Fragment>
          }
          minValue={retAgeLower}
          maxValue={inputs.spa}
          minValErrorMsg={`The retirement age cannot be less than ${retAgeLower}.`}
          maxValErrorMsg={`The retirement age cannot be greater than ${inputs.spa}.`}
        />
      ) : null}
      {valid.inReceipt &&
      valid.hasLeft &&
      valid.dateLeftScheme &&
      valid.legacyScheme &&
      valid.dateOfBirth &&
      valid.dateOfJoining &&
      valid.salaryCurrent &&
      valid.salary2015 &&
      valid.retirementAge ? (
        <Input
          input={"hasPT"}
          inputType={"select"}
          options={[{ value: "Yes" }, { value: "No" }]}
          text={"Have you ever been part-time?"}
          help={null}
        />
      ) : null}
      {inputs.hasPT === "Yes" ? <PartTimeServiceInput /> : null}
      {valid.inReceipt &&
      valid.hasLeft &&
      valid.dateLeftScheme &&
      valid.legacyScheme &&
      valid.dateOfBirth &&
      valid.dateOfJoining &&
      valid.salaryCurrent &&
      valid.salary2015 &&
      valid.retirementAge &&
      valid.hasPT ? (
        <div className={classes.button_container}>
          <CalculateButton />
        </div>
      ) : null}
      {/* <br></br>
      <p>Inputs:</p>
      <p>
        <pre>{JSON.stringify(inputs, null, 2)}</pre>
      </p>
      <p>Valids:</p>
      <p>
        <pre>{JSON.stringify(valid, null, 2)}</pre>
      </p> */}
    </div>
  );
}

export default YourDetailsPage;
