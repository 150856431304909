import React from "react";
import smallCPSLogo from "../../assets/images/cpsLogo-small.png";
import classes from "./CSPLogoContainer.module.css";

const CSPLogoContainer = (props) => {
  return (
    <div className={classes.main_container}>
      <img
        className={classes.chatIcon}
        src={smallCPSLogo}
        alt="Civil Service Pensions Logo" 
      />
      <main className={classes.textbox}>{props.children}</main>
    </div>
  );
};

export default CSPLogoContainer;
