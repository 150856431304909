import React, { Fragment } from "react";
import CPSLogoContainer from "../general/CSPLogoContainer";
import { currenyFormatter, sumValues } from "../../shared/utilities/Formatters";
import classes from "./SummaryHeader.module.css";

const SummaryHeader = (props) => {
  let totalReformedPension = currenyFormatter(
    sumValues(props.resultsPensionData.reformedPension)
  );
  let reformedLumpSum = currenyFormatter(
    props.resultsPensionData.lumpSums.reformed
  );
  let totalLegacyPension = currenyFormatter(
    sumValues(props.resultsPensionData.legacyPension)
  );
  let legacyLumpSum = currenyFormatter(
    props.resultsPensionData.lumpSums.legacy
  );

  return (
    <CPSLogoContainer>
      {props.showLumpSums && (
        <Fragment>
          <h3>Opt for {props.legacySchemeName} during remedy period</h3>
          <section className={classes.section}>
            <div>
              <p>
                Your estimated gross annual pension if you retire at age{" "}
                {props.resultsPensionData.retirementAge} is:
              </p>
              <h2>{totalLegacyPension}</h2>
            </div>
            <div>
              <p>
                Your estimated cash lump sum if you retire at age{" "}
                {props.resultsPensionData.retirementAge} is:
              </p>
              <h2>{legacyLumpSum}</h2>
            </div>
          </section>
          <h3>Opt for alpha during the remedy period</h3>
          <section className={classes.section}>
            <div>
              <p>
                Your estimated gross annual pension if you retire at age{" "}
                {props.resultsPensionData.retirementAge} is:
              </p>
              <h2>{totalReformedPension}</h2>
            </div>
            <div>
              <p>
                Your estimated cash lump sum if you retire at age{" "}
                {props.resultsPensionData.retirementAge} is:
              </p>
              <h2>{reformedLumpSum}</h2>
            </div>
          </section>
        </Fragment>
      )}
      {!props.showLumpSums && (
        <Fragment>
          <section className={classes.section}>
            <div>
              <h3>Opt for {props.legacySchemeName} during remedy period</h3>
              <p>
                Your estimated gross annual pension if you retire at age{" "}
                {props.resultsPensionData.retirementAge} is:
              </p>
              <h2>{totalLegacyPension}</h2>
            </div>
            <div>
              <h3>Opt for alpha during the remedy period</h3>
              <p>
                Your estimated gross annual pension if you retire at age{" "}
                {props.resultsPensionData.retirementAge} is:
              </p>
              <h2>{totalReformedPension}</h2>
            </div>
          </section>
        </Fragment>
      )}
    </CPSLogoContainer>
  );
};

export default SummaryHeader;
