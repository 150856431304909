import React, { Fragment, useState, useEffect, useRef } from "react";
// import { useLocation } from "react-router-dom";
import Header from "../components/layout/Header";
import TermsModal from "../components/layout/TermsModal";

import VideoModal from "../components/general/VideoModal";
import TermsVideo from "../assets/videos/RBI 1 Acknowledge 130923.mp4";
// import HomeVideo from "../assets/videos/RBI 2 HomePage 030323.mp4";
// import DetailsVideo from "../assets/videos/RBI 3 MemberInfo 080323.mp4";
// import ResultsVideo from "../assets/videos/RBI 4 Results 060323.mp4";

function Layout(props) {
  // const location = useLocation();

  // const previously_paused_home = useRef("Yes");
  // const previously_paused_results = useRef("No");
  const previously_paused_terms = useRef("No");
  // const previously_paused_details = useRef("No");

  // const HOME_PATHNAME = "/";
  // const DETAILS_PATHNAME = "/yourdetails";
  // const RESULTS_PATHNAME = "/results";

  const [showTermsVideo, setShowTermsVideo] = useState(true);
  // const [showHomeVideo, setShowHomeVideo] = useState(false);
  // const [showDetailsVideo, setShowDetailsVideo] = useState(false);
  // const [showResultsVideo, setShowResultsVideo] = useState(false);

  const [showModal, setShowModal] = useState(true);

  const closeModalHandler = () => {
    setShowModal(false);
    setShowTermsVideo(false);
    // setShowHomeVideo(true);
  };

  // useEffect(() => {
  //   if (showModal === false) {
  //     switch (location.pathname) {
  //       case HOME_PATHNAME:
  //         setShowHomeVideo(true);
  //         setShowDetailsVideo(false);
  //         setShowResultsVideo(false);
  //         break;
  //       case DETAILS_PATHNAME:
  //         setShowHomeVideo(false);
  //         setShowDetailsVideo(true);
  //         setShowResultsVideo(false);
  //         break;
  //       case RESULTS_PATHNAME:
  //         setShowHomeVideo(false);
  //         setShowDetailsVideo(false);
  //         setShowResultsVideo(true);
  //         break;
  //       default:
  //         setShowHomeVideo(false);
  //         setShowDetailsVideo(false);
  //         setShowResultsVideo(false);
  //         break;
  //     }
  //   }
  // }, [location.pathname]);

  return (
    <Fragment>
      <Header />
      <main>{props.children}</main>
      {showModal && (
        <Fragment>
          <TermsModal onClick={closeModalHandler} />
        </Fragment>
      )}
      {showTermsVideo && (
        <VideoModal
          videoTitle={"T&C Message Guide"}
          video={TermsVideo}
          previously_paused={previously_paused_terms}
        />
      )}
    </Fragment>
  );
}

export default Layout;
