import React, { Fragment, useState } from "react";
import InfoModal from "./InfoModal";

import classes from "./VideoModalMainText.module.css";

const MainTextModal = (props) => {  

  var at_top = props.at_top
  var extra_margin = (at_top ? '0' : '15px')
  const [showVideo, setShowVideo] = useState(false);

  const openVideo = () => {
    setShowVideo(true);
  };

  const closeVideo = () => {
    setShowVideo(false);
  };

  const closeModal = () => {
    props.setShowModal(false);
  };



  const infoMessage = (
    <Fragment>
      <h2>{props.videoTitle}</h2>
      <video className={classes.video} controls>
        <source src={props.video} type="video/mp4" />
      </video>
    </Fragment> 
  );

  return (
    <Fragment>
      {showVideo && (
        <InfoModal modalHandler={closeVideo} infoMessage={infoMessage} />
      )}
      {props.showModal && (
        <div 
        className={classes.modal + ' ' + (at_top ? '' : classes.modal_shifted)}
        >
          {at_top && <h3>Click the link below to open an explanatory video</h3>}
         
          <button className={classes.survey_btn} onClick={openVideo} style = {{'marginTop' : extra_margin}}>
            {at_top ? 'Open video' : 'Video'}
          </button>
          
          
          <button className={classes.close_btn} onClick={closeModal} style = {{'marginBottom' : extra_margin}}>
            Dismiss
          </button>
          
        </div>
      )}
    </Fragment>
  );
};

export default MainTextModal;
