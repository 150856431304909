import React, { Fragment, useState } from "react";
import InfoModal from "./InfoModal";

import classes from "./VideoModal.module.css";

const SurveyModal = (props) => { 

  const [showVideo, setShowVideo] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [modal_hovered, set_modal_hovered] = useState(false)

  const openVideo = () => {
    setShowVideo(true);
  };

  const closeVideo = () => {
    setShowVideo(false);
  };

  const closeModal = () => {
    setShowModal(false);
  };



  const infoMessage = (
    <Fragment>
      <h2>{props.videoTitle}</h2>
      <video className={classes.video} controls>
        <source src={props.video} type="video/mp4" />
      </video>
    </Fragment>
  );

  return (
    <Fragment>
      {showVideo && (
        <InfoModal modalHandler={closeVideo} infoMessage={infoMessage} />
      )}
      {showModal && (
        <div 
        className={classes.modal + ' ' + ( (modal_hovered | (props.previously_paused.current === 'Yes') ) ? classes.modal_hovered : '')}
        onMouseOver = {
          () => {
            set_modal_hovered(true)
            props.previously_paused.current = 'Yes'
          }
        
        }
        >
          <h3>See something you don't understand?</h3>
          <button className={classes.survey_btn} onClick={openVideo}>
            Open video
          </button>
          <button className={classes.close_btn} onClick={closeModal}>
            Dismiss
          </button>
        </div>
      )}
    </Fragment>
  );
};

export default SurveyModal;
