import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import params from "../../global_parameters";

export const TCText = [
  {
    id: "T&Cs",
    text: (
      <Fragment>
        <h1>Terms and Conditions</h1>
        <p>
          By using the illustrator, you agree you have read and understood the
          Terms & Conditions set out below.
        </p>
        <h2>1. Use of this illustrator</h2>
        <p>
          The illustrator is solely for guidance purposes and provides estimates
          only. It is not intended to provide financial advice. If you require
          financial advice, you should contact an independent financial adviser.
          The Government Actuary’s Department (GAD) and the {window.scheme_name}
          Pension Scheme and its administrators do not accept any liability for
          financial decisions taken based on the information provided in the
          illustrator.
        </p>
        <p>
          The results shown are an indication of the benefits you may receive;
          they are not guaranteed and you should not enter into financial
          commitments on the basis of the information provided.
        </p>
        <p>
          The illustrator does not override the scheme rules or pension
          legislation.
        </p>
        <p>
          This illustrator only applies for members in England, Scotland and
          Wales.
        </p>
        <h2>2. How your pension and lump sum have been estimated</h2>
        <h3>
          2.1. Choice of benefits accrued over 1 April {params.remedy_start} to
          31 March 2022
        </h3>
        <p>
          The results sections are based on members choosing to receive legacy
          scheme or alpha scheme benefits for the Remedy period 1 April{" "}
          {params.remedy_start} to 31 March 2022. Financial advice may be sought
          before making your choice. Member contributions have not been included
          in the illustrations.
        </p>
        <h3>2.2. Assumptions</h3>
        <p>
          The results pension and lump sum estimates are based on the following
          key assumptions:
        </p>
        <ul>
          <li>Consumer Price Inflation (CPI) is assumed to be 2% pa.</li>
          <li>
            Pensions in payment are assumed to increase at a rate of 2% pa.
          </li>
          <li>
            Your total alpha pension is adjusted in line with prices every year
            that you are an active member of the alpha scheme. This increase is
            assumed to occur at a rate of 2% pa.
          </li>
          <li>Salaries are assumed to increase at a rate of 3.8% pa.</li>
          <li>
            Salary increases do not allow for any future promotional salary
            increases you may receive.
          </li>
          <li>
            It is assumed that you will retire on normal terms (i.e. you are not
            retiring in ill-health).
          </li>
        </ul>
        <h3>2.3. Adjustments for early or late payment of pension</h3>
        <p>The amount of pension you receive depends on when you retire.</p>
        <p>
          This calculator applies adjustments when pension and lump sum are
          taken earlier or later than the relevant scheme’s Normal Pension Age
          in line with the scheme rules. The early and late retirement
          adjustment factors referred to are those currently in effect. However,
          they will be reviewed periodically, and may increase or decrease. Your
          benefits at retirement will depend on adjustment factors in effect at
          that time.
        </p>
        <h3>2.4. Cash lump sum</h3>
        <p>
          At retirement, you will get an automatic lump sum from service in the
          classic scheme. This is usually three times your pension (prior to any
          adjustment, if applicable). You are eligible to commute pension
          (exchange pension for a one-off lump sum) from all of the{" "}
          {window.scheme_name} pension schemes but this has not been allowed for
          in the illustrator, except where a scheme has an automatic lump sum
          and the equalise lump sum option has been selected. In this scenario,
          it is assumed that the standard automatic lump sum is taken in the
          legacy scheme scenario and that the commutation amount taken in the
          alpha scheme scenario is such that total lump sums are equal in both
          scenarios.
        </p>
        <h2>3. Taxation</h2>
        <p>
          The illustrations do not allow for taxation. Your benefits will be
          subject to the various tax rates and limits in force.
        </p>
      </Fragment>
    ),
  },
];

export const SmallPrintText = [
  {
    id: "SP",
    text: (
      <Fragment>
        <h2>The Small Print</h2>
        <p>
          The Remedy Benefits Illustrator is now available for all members who
          are affected by the Remedy. We would welcome your feedback on the
          survey provided to inform any future rollout.
        </p>
        <p>
          For <b>partial retirement</b>: If you have taken partial retirement,
          your position is more complicated than most members. The illustrator
          does not currently include partial retirement due to this additional
          complexity, but we hope to include it in a future version.
        </p>
        <p>
          Members who originally received transitional protection will also be
          provided with a choice of which scheme benefits they would prefer to
          receive for the Remedy period. As with non-transitionally protected
          members, the choice will be between either legacy or alpha scheme
          benefits for the whole Remedy period.
        </p>
        <p>
          For <b>member contributions</b>: Since April {params.remedy_start},
          member contribution rates have been the same regardless of which
          scheme a member was contributing toward. Therefore, the vast majority
          of members would have paid the same amount of contributions whether
          they were participating in their legacy scheme or the alpha scheme.
          There are a very small number of exceptions to this, who will have
          their contributions corrected when they receive their Remediable
          Service Statement (RSS).
        </p>
        <p>
          For the best experience the Remedy Benefits Illustrator should be
          completed on a desktop computer. Whilst the illustrator is mobile
          phone/tablet compatible, there may be compatibility issues on some
          models.
        </p>
        <p>
          We have provided guidance videos on each page to assist you on your
          journey through the illustrator. Please note that these videos might
          not play on certain work setups due to their security configurations.
          If this is the case, please try accessing them from a personal device.
        </p>
        <p>
          The illustrator will not check your information against other systems.
          If you enter inaccurate information, the outputs will be of limited
          value. No data you enter into this illustrator is stored or otherwise
          processed.
        </p>
        <p>
          The{" "}
          <a
            href="https://www.civilservicepensionscheme.org.uk/knowledge-centre/pension-schemes/"
            target="_blank"
          >
            scheme guides
          </a>{" "}
          will help you understand your benefits.
        </p>
        <p>
          By using this illustrator, you agree that you have read and understood
          the Terms & Conditions as set out in the{" "}
          <Link to="/T&Cs">T&Cs' tab</Link>.
        </p>
        <p>
          This illustrator is for guidance and illustration purposes only. The
          illustrator is designed to provide a basic illustration of your
          projected benefits at retirement from both your legacy scheme and
          alpha – other aspects such as added years, added pension, tax debits
          etc, are explicitly not covered. If you have these or similar
          additional options, the illustrator will be less accurate (though it
          may still be helpful). This illustrator is not intended to provide you
          with financial advice. If you require financial advice, you should
          contact an independent financial adviser.
        </p>
        <p>The assumptions we have used are:</p>
        <table>
          <tbody>
            <tr>
              <td>Inflation</td>
              <td>2% per year</td>
            </tr>
            <tr>
              <td>General pay increases</td>
              <td>3.8% per year</td>
            </tr>
          </tbody>
        </table>
        <p>
          For the comparison to make sense, we need to project your benefits.
          Therefore, the figures we show will not be equal to those in your
          Annual Benefit Statement.
        </p>
        <p>
          Where we project benefits in this illustrator, we present figures in{" "}
          <b>today's money terms</b>. However, we do offer you the ability to
          switch to nominal terms. Nominal terms will show the value of lump sum
          and pension in terms of monetary amounts at the time of payment.
          "Today's money terms" means the nominal value adjusted for inflation
          and therefore will represent what this pension would be worth today,
          i.e. so you can compare it with your income today and what you expect
          to need in retirement.
        </p>
      </Fragment>
    ),
  },
];

export const IntroText = [
  {
    id: "IT",
    text: (
      <Fragment>
        <h2>Welcome to the Remedy Benefits Illustrator</h2>
        <p>
          The Remedy gives you a choice, to be made at the point when you take
          your benefits. That choice is between your legacy scheme and the alpha
          scheme for the Remedy period (1 April {params.remedy_start} to 31
          March 2022). Both schemes offer highly valuable benefits.
        </p>
        <p>
          The illustrator is designed to provide members who are affected by the
          Remedy with an overview of how different choices may impact their
          benefits at retirement. It allows you to make an approximate
          comparison based on your inputs and certain assumptions about what
          happens in the future. It does not allow for all circumstances. If you
          intend to take your benefits within the next four months, you should
          also{" "}
          <a
            href="https://www.civilservicepensionscheme.org.uk/planning-for-retirement/how-to-claim-your-pension/"
            target="_blank"
          >
            seek a quote
          </a>
          .
        </p>
      </Fragment>
    ),
  },
];
