export const spaAPI = async (dateOfBirth, inputs, setInputs) => {
  let address =
    (window.LOCAL_SETTING ? window.LOCAL_URL : window.HOST_URL) + "/api/spa";

  const requestOptions = {
    method: "Post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ dob: dateOfBirth }),
  };

  fetch(address, requestOptions)
    .then((response) => response.json())
    .then((json) => setInputs({ ...inputs, spa: json["SPA"] }))
    .catch((error) => console.log(error));
};
