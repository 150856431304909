import React, { Fragment } from "react";
import { currenyFormatter, sumValues } from "../../shared/utilities/Formatters";
import classes from "./ComparisonTable.module.css";
import params from "../../shared/utilities/global_parameters";

const ComparisonTable = (props) => {
  return (
    <div className={classes.container}>
      <h2>Your Comparison Table</h2>
      <p>
        You can use this table to compare the expected benefits in the scenarios
        outlined above. The figures below are in {props.adjustInflation} terms.
      </p>
      <table className={classes.comp_table}>
        <thead>
          <tr>
            <td>Scenario No.</td>
            <td>Retirement Age</td>
            <td>Scheme</td>
            {props.showPreRemedy && <td>Pre-{params.remedy_start}</td>}
            <td>
              Remedy<br></br>({params.remedy_start}-22)
            </td>
            <td>Post-2022</td>
            <td>Total Pension</td>
            {props.showLumpSums && <td>Lump Sum</td>}
          </tr>
        </thead>
        <tbody>
          {props.scenariosCount == 6 && (
            <Fragment>
              <tr>
                <td>{props.scenariosCount - 5}</td>
                <td>
                  {props.compiledPensionsData.earlyRetirement.retirementAge}
                </td>
                <td>{props.legacySchemeName}</td>
                {props.showPreRemedy && (
                  <td>
                    {currenyFormatter(
                      props.compiledPensionsData.earlyRetirement.legacyPension
                        .preRemedy
                    )}
                  </td>
                )}
                <td>
                  {currenyFormatter(
                    props.compiledPensionsData.earlyRetirement.legacyPension
                      .remedy
                  )}
                </td>
                <td>
                  {currenyFormatter(
                    props.compiledPensionsData.earlyRetirement.legacyPension
                      .post2022
                  )}
                </td>
                <td>
                  {currenyFormatter(
                    sumValues(
                      props.compiledPensionsData.earlyRetirement.legacyPension
                    )
                  )}
                </td>
                {props.showLumpSums && (
                  <td>
                    {currenyFormatter(
                      props.compiledPensionsData.earlyRetirement.lumpSums.legacy
                    )}
                  </td>
                )}
              </tr>

              <tr>
                <td>{props.scenariosCount - 4}</td>
                <td>
                  {props.compiledPensionsData.earlyRetirement.retirementAge}
                </td>
                <td>{props.remedySchemeName}</td>
                {props.showPreRemedy && (
                  <td>
                    {currenyFormatter(
                      props.compiledPensionsData.earlyRetirement.reformedPension
                        .preRemedy
                    )}
                  </td>
                )}
                <td>
                  {currenyFormatter(
                    props.compiledPensionsData.earlyRetirement.reformedPension
                      .remedy
                  )}
                </td>
                <td>
                  {currenyFormatter(
                    props.compiledPensionsData.earlyRetirement.reformedPension
                      .post2022
                  )}
                </td>
                <td>
                  {currenyFormatter(
                    sumValues(
                      props.compiledPensionsData.earlyRetirement.reformedPension
                    )
                  )}
                </td>
                {props.showLumpSums && (
                  <td>
                    {currenyFormatter(
                      props.compiledPensionsData.earlyRetirement.lumpSums
                        .reformed
                    )}
                  </td>
                )}
              </tr>
            </Fragment>
          )}
          {props.scenariosCount >= 4 && (
            <Fragment>
              <tr>
                <td>{props.scenariosCount - 3}</td>
                <td>
                  {props.compiledPensionsData.normalRetirement.retirementAge}
                </td>
                <td>{props.legacySchemeName}</td>
                {props.showPreRemedy && (
                  <td>
                    {currenyFormatter(
                      props.compiledPensionsData.normalRetirement.legacyPension
                        .preRemedy
                    )}
                  </td>
                )}
                <td>
                  {currenyFormatter(
                    props.compiledPensionsData.normalRetirement.legacyPension
                      .remedy
                  )}
                </td>
                <td>
                  {currenyFormatter(
                    props.compiledPensionsData.normalRetirement.legacyPension
                      .post2022
                  )}
                </td>
                <td>
                  {currenyFormatter(
                    sumValues(
                      props.compiledPensionsData.normalRetirement.legacyPension
                    )
                  )}
                </td>
                {props.showLumpSums && (
                  <td>
                    {currenyFormatter(
                      props.compiledPensionsData.normalRetirement.lumpSums
                        .legacy
                    )}
                  </td>
                )}
              </tr>
              <tr>
                <td>{props.scenariosCount - 2}</td>
                <td>
                  {props.compiledPensionsData.normalRetirement.retirementAge}
                </td>
                <td>{props.remedySchemeName}</td>
                {props.showPreRemedy && (
                  <td>
                    {currenyFormatter(
                      props.compiledPensionsData.normalRetirement
                        .reformedPension.preRemedy
                    )}
                  </td>
                )}
                <td>
                  {currenyFormatter(
                    props.compiledPensionsData.normalRetirement.reformedPension
                      .remedy
                  )}
                </td>
                <td>
                  {currenyFormatter(
                    props.compiledPensionsData.normalRetirement.reformedPension
                      .post2022
                  )}
                </td>
                <td>
                  {currenyFormatter(
                    sumValues(
                      props.compiledPensionsData.normalRetirement
                        .reformedPension
                    )
                  )}
                </td>
                {props.showLumpSums && (
                  <td>
                    {currenyFormatter(
                      props.compiledPensionsData.normalRetirement.lumpSums
                        .reformed
                    )}
                  </td>
                )}
              </tr>
            </Fragment>
          )}
          <tr>
            <td>{props.scenariosCount - 1}</td>
            <td>{props.compiledPensionsData.lateRetirement.retirementAge}</td>
            <td>{props.legacySchemeName}</td>
            {props.showPreRemedy && (
              <td>
                {currenyFormatter(
                  props.compiledPensionsData.lateRetirement.legacyPension
                    .preRemedy
                )}
              </td>
            )}
            <td>
              {currenyFormatter(
                props.compiledPensionsData.lateRetirement.legacyPension.remedy
              )}
            </td>
            <td>
              {currenyFormatter(
                props.compiledPensionsData.lateRetirement.legacyPension.post2022
              )}
            </td>
            <td>
              {currenyFormatter(
                sumValues(
                  props.compiledPensionsData.lateRetirement.legacyPension
                )
              )}
            </td>
            {props.showLumpSums && (
              <td>
                {currenyFormatter(
                  props.compiledPensionsData.lateRetirement.lumpSums.legacy
                )}
              </td>
            )}
          </tr>
          <tr>
            <td>{props.scenariosCount}</td>
            <td>{props.compiledPensionsData.lateRetirement.retirementAge}</td>
            <td>{props.remedySchemeName}</td>
            {props.showPreRemedy && (
              <td>
                {currenyFormatter(
                  props.compiledPensionsData.lateRetirement.reformedPension
                    .preRemedy
                )}
              </td>
            )}
            <td>
              {currenyFormatter(
                props.compiledPensionsData.lateRetirement.reformedPension.remedy
              )}
            </td>
            <td>
              {currenyFormatter(
                props.compiledPensionsData.lateRetirement.reformedPension
                  .post2022
              )}
            </td>
            <td>
              {currenyFormatter(
                sumValues(
                  props.compiledPensionsData.lateRetirement.reformedPension
                )
              )}
            </td>
            {props.showLumpSums && (
              <td>
                {currenyFormatter(
                  props.compiledPensionsData.lateRetirement.lumpSums.reformed
                )}
              </td>
            )}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ComparisonTable;
