import React, { useState, useContext, Fragment, useEffect } from "react";
import InfoModal from "../../general/InfoModal";
import { InputDataContext } from "../../../shared/contexts/InputDataContext";
import "react-datepicker/dist/react-datepicker.css";

import classes from "./Inputs.module.css";

const Input = (props) => {
  const { inputs, setInputs, valid, setValid } = useContext(InputDataContext);
  const [showModal, setShowModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);

  const modalHandler = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setShowError(true);
      }, 1000);
    }
    if (!error) {
      setShowError(false);
    }
  }, [error]);

  const changeHandler = (event) => {
    if (props.inputType === "number") {
      let userInput = Number(event.target.value);
      let minValue = props.minValue;
      let maxValue = props.maxValue;

      if (userInput < minValue) {
        setError(props.minValErrorMsg);
        setValid({ ...valid, [props.input]: false });
        return;
      }

      if (userInput > maxValue) {
        setError(props.maxValErrorMsg);
        setValid({ ...valid, [props.input]: false });
        return;
      }
    }

    if (props.inputType === "date") {
      let userInput = new Date(event.target.value);
      let dateMinValue = new Date(props.minValue);
      let dateMaxValue = new Date(props.maxValue);

      if (userInput < dateMinValue) {
        setError(props.minValErrorMsg);
        setValid({ ...valid, [props.input]: false });
        return;
      }

      if (userInput > dateMaxValue) {
        setError(props.maxValErrorMsg);
        setValid({ ...valid, [props.input]: false });
        return;
      }
    }

    setValid({ ...valid, [props.input]: true });
    setInputs({ ...inputs, [props.input]: event.target.value });
    setError(null);
  };

  const infoMessage = props.help;

  let options = null;
  if (props.options) {
    options = props.options.map((item) => {
      return (
        <option key={item.value} value={item.value}>
          {item.value}
        </option>
      );
    });
  }

  return (
    <Fragment>
      <div className={classes.input_container}>
        {showModal && (
          <InfoModal modalHandler={modalHandler} infoMessage={infoMessage} />
        )}
        <section>
          <p>{props.text}</p>
          {infoMessage && <button onClick={modalHandler}>Help</button>}
        </section>
        {props.inputType === "select" && (
          <select
            defaultValue={inputs[props.input]}
            onChange={changeHandler}
            className={classes.input2}
          >
            <option value="" disabled selected hidden></option>
            {options}
          </select>
        )}
        {props.inputType === "date" && (
          <input
            defaultValue={inputs[props.input]}
            type="date"
            onChange={changeHandler}
            className={classes.input}
          />
        )}
        {props.inputType === "number" && (
          <input
            defaultValue={inputs[props.input]}
            type="number"
            onChange={changeHandler}
            min={props.minValue}
            step={1}
            max={props.maxValue}
            className={classes.input}
          />
        )}
        {showError && error && <p className={classes.ErrorMsg}>{error}</p>}
      </div>
    </Fragment>
  );
};

export default Input;
