import React from "react";
import classes from "./NuvosWarning.module.css";
import params from "../../shared/utilities/global_parameters";

const NuvosWarning = () => {
  return (
    <div className={classes.container}>
      <p>
        Please note that the figures provided below do not include the pension
        you accrued before {params.remedy_start}.
      </p>
      <p>
        However, your choice impacts the {params.remedy_start}-22 period only:
        pre-{params.remedy_start} pension is not affected by the remedy.
      </p>
    </div>
  );
};

export default NuvosWarning;
