import React, { useContext, useEffect, useState } from "react";
import { InputDataContext } from "../../../shared/contexts/InputDataContext";
import classes from "./ServiceInputs.module.css";

const PartTimeServiceInput = () => {
  const { inputs, setInputs } = useContext(InputDataContext);
  const [partTimeService, setPartTimeService] = useState(
    inputs.partTimeService
  );
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [percentage, setPercentage] = useState("");
  const [error, setError] = useState(null);

  const addService = () => {
    const newService = {
      From: startDate,
      To: endDate,
      Proportion: percentage,
    };

    if (startDate >= endDate) {
      setError("Start date must be before end date.");
      return;
    }

    if (percentage < 0 || percentage > 99) {
      setError("Percentage must be between 0% and 99%.");
      return;
    }

    const isOverlap = (service1, service2) => {
      const start1 = new Date(service1.From);
      const end1 = new Date(service1.To);
      const start2 = new Date(service2.From);
      const end2 = new Date(service2.To);

      return start1 <= end2 && start2 <= end1;
    };

    for (let i = 0; i < partTimeService.length; i++) {
      if (isOverlap(partTimeService[i], newService)) {
        setError("This entry overlaps with an existing part-time service.");
        return;
      }
    }

    const isChronalogical = (service1, service2) => {
      const start1 = new Date(service1.From);
      const start2 = new Date(service2.From);

      return start1 >= start2;
    };

    for (let i = 0; i < partTimeService.length; i++) {
      if (isChronalogical(partTimeService[i], newService)) {
        setError("Please enter services in chronalogoical order.");
        return;
      }
    }

    setPartTimeService([
      ...partTimeService,
      { From: startDate, To: endDate, Proportion: percentage },
    ]);

    setStartDate("");
    setEndDate("");
    setPercentage("");
    setError(null);
  };

  const removeService = (index) => {
    setPartTimeService(partTimeService.filter((_, i) => i !== index));
  };

  useEffect(() => {
    setInputs({ ...inputs, partTimeService: partTimeService });
  }, [partTimeService]);

  return (
    <div className={classes.service_container}>
      <h3>Tell us about your part-time working history</h3>
      <p>
        If you have never served a period of part-time service then leave this
        blank or select 'No' for the preceding question.
      </p>
      <p>
        Please enter periods of part-time service in chronological order. You
        can provide anticipated future periods of part-time service as well as
        periods in the past.
      </p>
      <p>
        If you have had more than one period of part-time service, enter details
        for each period. The value in the 'Percentage' field should have a
        number between 0 and 99 to reflect the percentage of full time
        equivalent hours that you worked during the relevant period. For
        example, if you worked 28 hours per week when the full time equivalent
        was 35 hours per week, your part-time proportion would be 80%, in which
        case you should write '80' in the percentage field.
      </p>
      <p>
        Please note that you do not need to enter any full-time periods of
        working if they fall in between separate periods of part-time working.
      </p>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <div className={classes.subContainer}>
        <div className={classes.inputContainer}>
          <div className={classes.label_input}>
            <label>Start Date:</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className={classes.input}
            />
          </div>
          <div className={classes.label_input}>
            <label>End Date:</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className={classes.input}
            />
          </div>
          <div className={classes.label_input}>
            <label>Percentage (%):</label>
            <input
              type="number"
              value={percentage}
              onChange={(e) => setPercentage(e.target.value)}
              min={0}
              step={1}
              max={99}
              className={classes.input}
            />
          </div>
        </div>
        <button onClick={addService}>Add Service</button>
      </div>
      <table>
        <thead>
          <tr>
            <th className={classes.leftColumn}></th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Proportion</th>
            <th className={classes.rightColumn}></th>
          </tr>
        </thead>
        <tbody>
          {partTimeService.map((service, i) => (
            <tr key={i}>
              <td className={classes.leftColumn}>{i + 1}.</td>
              <td>{new Date(service.From).toLocaleDateString("en-GB")}</td>
              <td>{new Date(service.To).toLocaleDateString("en-GB")}</td>
              <td>{service.Proportion}%</td>
              <td className={classes.rightColumn}>
                <button onClick={() => removeService(i)}>X</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PartTimeServiceInput;
