export const submitInputData = (inputs, setOutputData) => {
  let address =
    (window.LOCAL_SETTING ? window.LOCAL_URL : window.HOST_URL) + "/api/setup";

  const InputData = {
    SPA: inputs.spa,
    dateJoinedScheme: inputs.dateOfJoining,
    dateOfBirth: inputs.dateOfBirth,
    if_left: inputs.hasLeft,
    if_pt: inputs.hasPT,
    partTime: inputs.partTimeService,
    receipt: inputs.inReceipt,
    retirement_age: inputs.retirementAge,
    salary1: inputs.salaryCurrent,
    salary2: inputs.salary2015,
    scheme: inputs.legacyScheme,
    salaryIncreases: inputs.salaryIncrease / 100,
    dateLeftScheme: inputs.dateLeftScheme,
  };

  const requestOptions = {
    method: "Post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(InputData),
  };

  fetch(address, requestOptions)
    .then((response) => response.json())
    .then((json) => setOutputData(json))
    .catch((error) => console.log(error));
};
