import React from "react";
import classes from "./SurveyBanner.module.css";

const SurveyBanner = (props) => {
  return (
    <div className={classes.SurveyContainer}>
      <h3>Give us feedback!</h3>
      <p>
        The Remedy Benefits Illustrator is still under development and we would
        love to know what you think so we can keep making improvements.
      </p>
      <p>
        If you would like to give us feedback you can{" "}
        <a
          href="https://www.smartsurvey.co.uk/s/gad-remedy-benefits-illustrator/"
          target="_blank"
        >
          access our survey here.
        </a>
      </p>
    </div>
  );
};

export default SurveyBanner;
