import React, { Fragment, useState } from "react";
import InfoModal from "../../general/InfoModal";

import classes from "./ControlSwitches.module.css";

const RetirementAgeSwtich = (props) => {
  const [showModal, setShowModal] = useState(false);

  const modalHandler = () => {
    setShowModal(!showModal);
  };

  const infoMessage = (
    <Fragment>
      <h2>Adjust Retirement Age</h2>
      <p>
        You can use these buttons below to provide a simple illustration of the
        annual pension and lump sum that you may receive in scenarios relevant
        to you.
      </p>
    </Fragment>
  );

  return (
    <div className={classes.main_container}>
      {showModal && (
        <InfoModal modalHandler={modalHandler} infoMessage={infoMessage} />
      )}
      <header className={classes.header}>
        <h3>Adjust Retirement Age</h3>
        <button
          id="RetirementAgeSwitchHelp"
          className={classes.help_button}
          onClick={modalHandler}
        >
          Help
        </button>
      </header>
      <p>
        You can use these buttons below to control the retirement age in the
        illustration of the annual pension and lump sum shown in the charts
        below.
      </p>
      <div className={classes.radio_container1}>
        {props.scenarioName === "early_desired_late" && (
          <Fragment>
            <input
              type="radio"
              id="radioEarly"
              name="radioRetirementAge"
              value="early"
              onChange={() => props.setRetirementAge("early")}
            />
            <label htmlFor="radioEarly">Early Retirement</label>
            <input
              type="radio"
              id="radioNormal"
              name="radioRetirementAge"
              value="normal"
              onChange={() => props.setRetirementAge("normal")}
              defaultChecked
            />
            <label htmlFor="radioNormal">Desired Retirement Age</label>
            <input
              type="radio"
              id="radioLate"
              name="radioRetirementAge"
              value="late"
              onChange={() => props.setRetirementAge("late")}
            />
            <label htmlFor="radioLate">Late Retirement</label>
          </Fragment>
        )}
        {props.scenarioName === "desired_late" && (
          <Fragment>
            <input
              type="radio"
              id="radioNormal"
              name="radioRetirementAge"
              value="normal"
              onChange={() => props.setRetirementAge("normal")}
              defaultChecked
            />
            <label htmlFor="radioNormal">Desired Retirement Age</label>
            <input
              type="radio"
              id="radioLate"
              name="radioRetirementAge"
              value="late"
              onChange={() => props.setRetirementAge("late")}
            />
            <label htmlFor="radioLate">Late Retirement </label>
          </Fragment>
        )}
        {props.scenarioName === "early_desired" && (
          <Fragment>
            <input
              type="radio"
              id="radioNormal"
              name="radioRetirementAge"
              value="normal"
              onChange={() => props.setRetirementAge("normal")}
              defaultChecked
            />
            <label htmlFor="radioNormal">Early Retirement</label>
            <input
              type="radio"
              id="radioLate"
              name="radioRetirementAge"
              value="late"
              onChange={() => props.setRetirementAge("late")}
            />
            <label htmlFor="radioLate">Desired Retirement Age</label>
          </Fragment>
        )}
        {props.scenarioName === "desired" && (
          <Fragment>
            <input
              type="radio"
              id="radioLate"
              name="radioRetirementAge"
              value="late"
              onChange={() => props.setRetirementAge("late")}
              defaultChecked
            />
            <label htmlFor="radioLate">Desired Retirement Age</label>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default RetirementAgeSwtich;
