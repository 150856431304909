import React, { Fragment } from "react";
import params from "../../global_parameters";

const FrontPageQuestionSelectorText = [
  {
    id: "FAQs-Q1",
    question: "What does this illustrator do?",
    answer: (
      <p>
        The Remedy Benefits Illustrator provides an illustration of the
        projected basic service pension and lump sum amounts for your legacy
        scheme and the alpha scheme.
      </p>
    ),
  },
  {
    id: "FAQs-Q2",
    question: "Where will I find my information to complete the illustrator?",
    answer: (
      <Fragment>
        <p>
          It will be really handy if you have a copy of your latest annual
          benefit statement available. By clicking{" "}
          <a
            href="https://members.civilservicepensionscheme.org.uk/"
            target="_blank"
          >
            this link
          </a>{" "}
          you can log into the Pension Portal and see it online. Information on
          your payslip may also assist. In most cases an estimate is fine if not
          available.
        </p>
        <p>
          We’ll ask you for information including: if you have you already left
          the scheme, your date of birth, the date you joined the scheme, your
          current pensionable pay and your pensionable pay at{" "}
          {params.remedy_start} (the annual amount before tax), your part-time
          work history.
        </p>
      </Fragment>
    ),
  },
  {
    id: "FAQs-Q3",
    question: "Do I have to make a choice?",
    answer: (
      <p>
        If you are affected by the {params.remedy_start} Remedy, you will need
        to make a choice at some point after October 2023. Your pension
        administrator will be in touch when you are eligible to make a choice.
      </p>
    ),
  },
  {
    id: "FAQs-Q4",
    question: "What are the legacy schemes?",
    answer: (
      <p>
        The legacy schemes refer to the classic, premium, classic plus and nuvos
        arrangements.
      </p>
    ),
  },
  {
    id: "FAQs-Q5",
    question: "What is my retirement age?",
    answer: (
      <p>
        The illustrator assumes you will take your benefits at the Normal
        Pension Age which applied to your legacy scheme, unless you have passed
        this age, in which case the main scenario assumes immediate retirement.
        For a fair comparison, alpha benefits are adjusted to the relevant age.
      </p>
    ),
  },
  {
    id: "FAQs-Q6",
    question: "What if I intend to leave service, or retire earlier or later?",
    answer: (
      <p>
        You will be able to illustrate your basic service benefits at earlier or
        later retirement ages.
      </p>
    ),
  },
];

export default FrontPageQuestionSelectorText;
