import React, { Fragment, useContext, useEffect, useState } from "react";
import PensionBarChart from "../components/results/charts/PensionBarChart";
import LumpSumBarChart from "../components/results/charts/LumpSumBarChart";
import SummaryHeader from "../components/results/SummaryHeader";
import RetirementAgeSwtich from "../components/results/switches/RetirementAgeSwitch";
import InflationSwitch from "../components/results/switches/InflationSwitch";
import LumpSumSwitch from "../components/results/switches/LumpSumSwitch";
import SalarySlider from "../components/results/switches/SalarySlider";
import ComparisonTable from "../components/results/ComparisonTable";
import NuvosWarning from "../components/results/NuvosWarning";
import ResultsVideo from "../assets/videos/RBI 4 Results 210923.mp4";
import MainTextModal from "../components/general/VideoModalMainText";
import SurveyBanner from "../components/results/SurveyBanner";

import { OutputDataContext } from "../App";
import { submitInputData } from "../shared/apis/mainAPI";
import { InputDataContext } from "../shared/contexts/InputDataContext";
import { useScrollPosition } from "../shared/utilities/useScrollPosition";

import classes from "./3-Results.module.css";

function ResultsPage() {
  const { inputs, setInputs } = useContext(InputDataContext);
  const { outputData, setOutputData } = useContext(OutputDataContext);
  const [adjustInflation, setAdjustInflation] = useState("real");
  const [equaliseLumpSum, setEqualiseLumpSum] = useState("unequal");
  const [lumpSumValue, setLumpSumValue] = useState(0);
  const [retirementAge, setRetirementAge] = useState("normal");
  const [salaryIncrease, setSalaryIncrease] = useState(inputs.salaryIncrease);
  const [mouseDown, setMouseDown] = useState(false);
  const scrollPosition = useScrollPosition();
  const [isScrolledDown, setIsScrolledDown] = useState(false);
  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    if (scrollPosition > 90) {
      setIsScrolledDown(true);
    } else {
      setIsScrolledDown(false);
    }
  }, [scrollPosition]);

  useEffect(() => {
    if (!mouseDown) {
      setInputs({ ...inputs, salaryIncrease: Number(salaryIncrease) });
    }
  }, [mouseDown]);

  useEffect(() => {
    submitInputData(inputs, setOutputData);
  }, [inputs]);

  let USER_DATA = outputData["results2"][adjustInflation];
  let userRetirementAge = outputData["retirement_age"];
  let userExactAge = outputData["exact_age"];
  let remedySchemeName = "alpha";
  let legacySchemeName = USER_DATA["labels"]["scheme"][1];
  let scenariosCount = outputData["n_scenarios"] * 2;

  let showLumpSums = true;
  if (legacySchemeName === "premium" || legacySchemeName === "nuvos") {
    showLumpSums = false;
  }

  let showEqualiseSwitch = true;
  if (legacySchemeName === "classic plus") {
    showEqualiseSwitch = false;
  }

  let showPreRemedy = true;
  let showNuvosWarning = false;
  if (legacySchemeName === "nuvos") {
    showPreRemedy = false;
    showNuvosWarning = true;
  }

  let lumpSumArraySting;
  if (equaliseLumpSum === "equal") {
    lumpSumArraySting = "";
  } else {
    lumpSumArraySting = "_unequal";
  }

  let scenarioName;

  let showInflationSwitch = true;
  switch (true) {
    case scenariosCount === 6:
      scenarioName = "early_desired_late";
      break;
    case scenariosCount === 4 &&
      Math.min(...USER_DATA["labels"]["ages"]) === Number(userRetirementAge):
      scenarioName = "desired_late";
      break;
    case scenariosCount === 4 &&
      Math.max(...USER_DATA["labels"]["ages"]) === Number(userRetirementAge):
      scenarioName = "early_desired";
      break;
    case scenariosCount === 2:
      scenarioName = "desired";
      break;
  }

  /* really, it should not be possible for the retirement age to be below the exact age; in theory we should be able to
use `==` in the condition below instead of `<=`. However, to account for the possibility of the exact age being reduced
by a tiny amount (e.g. due to the way python treats floats), I have used <= instead. */
  if (userRetirementAge <= userExactAge) {
    showInflationSwitch = false;
  }

  let compiledPensionsData = {
    earlyRetirement: {
      retirementAge: parseInt(USER_DATA["labels"]["ages"][scenariosCount - 6]),
      reformedPension: {
        preRemedy: showPreRemedy
          ? USER_DATA["data_pre_remedy"][scenariosCount - 6]
          : 0,
        remedy:
          USER_DATA["data_remedy" + lumpSumArraySting][scenariosCount - 6],
        post2022:
          USER_DATA["data_2022" + lumpSumArraySting][scenariosCount - 6],
      },
      legacyPension: {
        preRemedy: showPreRemedy
          ? USER_DATA["data_pre_remedy"][scenariosCount - 5]
          : 0,
        remedy:
          USER_DATA["data_remedy" + lumpSumArraySting][scenariosCount - 5],
        post2022:
          USER_DATA["data_2022" + lumpSumArraySting][scenariosCount - 5],
      },
      lumpSums: {
        reformed: USER_DATA["lump sum" + lumpSumArraySting][scenariosCount - 6],
        legacy: USER_DATA["lump sum" + lumpSumArraySting][scenariosCount - 5],
      },
    },
    normalRetirement: {
      retirementAge: parseInt(USER_DATA["labels"]["ages"][scenariosCount - 4]),
      reformedPension: {
        preRemedy: showPreRemedy
          ? USER_DATA["data_pre_remedy"][scenariosCount - 4]
          : 0,
        remedy:
          USER_DATA["data_remedy" + lumpSumArraySting][scenariosCount - 4],
        post2022:
          USER_DATA["data_2022" + lumpSumArraySting][scenariosCount - 4],
      },
      legacyPension: {
        preRemedy: showPreRemedy
          ? USER_DATA["data_pre_remedy"][scenariosCount - 3]
          : 0,
        remedy:
          USER_DATA["data_remedy" + lumpSumArraySting][scenariosCount - 3],
        post2022:
          USER_DATA["data_2022" + lumpSumArraySting][scenariosCount - 3],
      },
      lumpSums: {
        reformed: USER_DATA["lump sum" + lumpSumArraySting][scenariosCount - 4],
        legacy: USER_DATA["lump sum" + lumpSumArraySting][scenariosCount - 3],
      },
    },
    lateRetirement: {
      retirementAge: parseInt(USER_DATA["labels"]["ages"][scenariosCount - 2]),
      reformedPension: {
        preRemedy: showPreRemedy
          ? USER_DATA["data_pre_remedy"][scenariosCount - 2]
          : 0,
        remedy:
          USER_DATA["data_remedy" + lumpSumArraySting][scenariosCount - 2],
        post2022:
          USER_DATA["data_2022" + lumpSumArraySting][scenariosCount - 2],
      },
      legacyPension: {
        preRemedy: showPreRemedy
          ? USER_DATA["data_pre_remedy"][scenariosCount - 1]
          : 0,
        remedy:
          USER_DATA["data_remedy" + lumpSumArraySting][scenariosCount - 1],
        post2022:
          USER_DATA["data_2022" + lumpSumArraySting][scenariosCount - 1],
      },
      lumpSums: {
        reformed: USER_DATA["lump sum" + lumpSumArraySting][scenariosCount - 2],
        legacy: USER_DATA["lump sum" + lumpSumArraySting][scenariosCount - 1],
      },
    },
  };

  let userPensionData;
  switch (scenarioName) {
    case "early_desired":
      userPensionData = (({ normalRetirement, lateRetirement }) => ({
        normalRetirement,
        lateRetirement,
      }))(compiledPensionsData);
      break;
    case "desired_late":
      userPensionData = (({ normalRetirement, lateRetirement }) => ({
        normalRetirement,
        lateRetirement,
      }))(compiledPensionsData);
      break;
    case "early_desired_late":
      userPensionData = (({
        earlyRetirement,
        normalRetirement,
        lateRetirement,
      }) => ({
        earlyRetirement,
        normalRetirement,
        lateRetirement,
      }))(compiledPensionsData);
      break;
    case "desired":
      userPensionData = (({ lateRetirement }) => ({
        lateRetirement,
      }))(compiledPensionsData);
      break;
  }

  let resultsPensionData;
  if (scenarioName === "desired") {
    resultsPensionData = userPensionData["lateRetirement"];
  } else {
    resultsPensionData = userPensionData[retirementAge + "Retirement"];
  }

  return (
    <div id="Results_Page">
      {showModal && <div style={{ height: "9.75rem" }}></div>}
      <MainTextModal
        videoTitle={"Results Page Guide"}
        video={ResultsVideo}
        at_top={!isScrolledDown}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <SurveyBanner />
      <h2>Your Illustrated Results</h2>
      {showLumpSums ? (
        <p>
          The following page provides a simple illustration of the annual
          pension and lump sum that you may receive at different retirement ages
          depending on whether you choose legacy or alpha benefits for the
          remedy period.
        </p>
      ) : (
        <p>
          The following page provides a simple illustration of the annual
          pension that you may receive at different retirement ages depending on
          whether you choose legacy or alpha benefits for the remedy period.
        </p>
      )}
      {showNuvosWarning && <NuvosWarning />}
      <SummaryHeader
        showLumpSums={showLumpSums}
        legacySchemeName={legacySchemeName}
        remedySchemeName={remedySchemeName}
        resultsPensionData={resultsPensionData}
        adjustInflation={adjustInflation}
      />
      <RetirementAgeSwtich
        scenarioName={scenarioName}
        setRetirementAge={setRetirementAge}
      />
      {showLumpSums && (
        <Fragment>
          <div className={classes.chart_container}>
            <div className={classes.chart}>
              <PensionBarChart
                key={1}
                showPreRemedy={showPreRemedy}
                retirementAge={resultsPensionData.retirementAge}
                reformedPreRemedy={resultsPensionData.reformedPension.preRemedy}
                reformedRemedy={resultsPensionData.reformedPension.remedy}
                reformedPost2022={resultsPensionData.reformedPension.post2022}
                legacyPreRemedy={resultsPensionData.legacyPension.preRemedy}
                legacyRemedy={resultsPensionData.legacyPension.remedy}
                legacyPost2022={resultsPensionData.legacyPension.post2022}
                scheme={legacySchemeName}
                adjustInflationState={adjustInflation}
                equaliseLumpSumState={equaliseLumpSum}
                retirementAgeState={retirementAge}
                outputData={outputData}
                salaryIncrease={salaryIncrease}
              />
            </div>
            <div className={classes.chart}>
              <LumpSumBarChart
                key={2}
                retirementAge={resultsPensionData.retirementAge}
                reformedLumpSum={resultsPensionData.lumpSums.reformed}
                legacyLumpSum={resultsPensionData.lumpSums.legacy}
                scheme={legacySchemeName}
                adjustInflationState={adjustInflation}
                equaliseLumpSumState={equaliseLumpSum}
                retirementAgeState={retirementAge}
                outputData={outputData}
                salaryIncrease={salaryIncrease}
              />
            </div>
          </div>
          <div className={classes.switch_container}>
            {showInflationSwitch && (
              <InflationSwitch setAdjustInflation={setAdjustInflation} />
            )}
            {showEqualiseSwitch && (
              <LumpSumSwitch
                setEqualiseLumpSum={setEqualiseLumpSum}
                lumpSumValue={lumpSumValue}
                setLumpSumValue={setLumpSumValue}
              />
            )}
            <SalarySlider
              salaryIncrease={salaryIncrease}
              setSalaryIncrease={setSalaryIncrease}
              setMouseDown={setMouseDown}
              legacySchemeName={legacySchemeName}
            />
          </div>
        </Fragment>
      )}
      {!showLumpSums && (
        <Fragment>
          <div className={classes.joint_container}>
            <div className={classes.chart2}>
              <PensionBarChart
                key={1}
                showPreRemedy={showPreRemedy}
                retirementAge={resultsPensionData.retirementAge}
                reformedPreRemedy={resultsPensionData.reformedPension.preRemedy}
                reformedRemedy={resultsPensionData.reformedPension.remedy}
                reformedPost2022={resultsPensionData.reformedPension.post2022}
                legacyPreRemedy={resultsPensionData.legacyPension.preRemedy}
                legacyRemedy={resultsPensionData.legacyPension.remedy}
                legacyPost2022={resultsPensionData.legacyPension.post2022}
                scheme={legacySchemeName}
                adjustInflationState={adjustInflation}
                equaliseLumpSumState={equaliseLumpSum}
                retirementAgeState={retirementAge}
                outputData={outputData}
                salaryIncrease={salaryIncrease}
              />
            </div>
          </div>
          <div className={classes.switch_container}>
            {showInflationSwitch && (
              <InflationSwitch setAdjustInflation={setAdjustInflation} />
            )}
            <SalarySlider
              salaryIncrease={salaryIncrease}
              setSalaryIncrease={setSalaryIncrease}
              setMouseDown={setMouseDown}
              legacySchemeName={legacySchemeName}
            />
          </div>
        </Fragment>
      )}
      <ComparisonTable
        showLumpSums={showLumpSums}
        showPreRemedy={showPreRemedy}
        scenariosCount={scenariosCount}
        legacySchemeName={legacySchemeName}
        remedySchemeName={remedySchemeName}
        compiledPensionsData={compiledPensionsData}
        adjustInflation={adjustInflation}
      />
    </div>
  );
}

export default ResultsPage;
