import React, { Fragment, useState } from "react";
import InfoModal from "../../general/InfoModal";

import classes from "./ControlSwitches.module.css";

const InflationSwitch = (props) => {
  const [showModal, setShowModal] = useState(false);

  const modalHandler = () => {
    setShowModal(!showModal);
  };

  const infoMessage = (
    <Fragment>
      <h2>Adjust for Inflation</h2>
      <p>
        You can choose to display your pension and lump sum in real or nominal
        terms. Nominal terms will show the value of lump sum and pension in
        terms of monetary amounts at the point of payment. Real terms is the
        nominal value adjusted for inflation and therefore will represent value
        in current money terms.
      </p>
    </Fragment>
  );

  return (
    <div className={classes.main_container}>
      {showModal && (
        <InfoModal modalHandler={modalHandler} infoMessage={infoMessage} />
      )}
      <header className={classes.header}>
        <h3>Adjust for Inflation</h3>
        <button
          id="InflationSwitchHelp"
          className={classes.help_button}
          onClick={modalHandler}
        >
          Help
        </button>
      </header>
      <p>
        You can use these buttons to choose whether or not the illustration
        results should be in real or nominal values.
      </p>
      <div className={classes.radio_container}>
        <input
          type="radio"
          id="radioReal"
          name="radioInflation"
          value="real"
          onChange={() => props.setAdjustInflation("real")}
          defaultChecked
        />
        <label htmlFor="radioReal">Real</label>
        <input
          type="radio"
          id="radioNominal"
          name="radioInflation"
          value="real"
          onChange={() => props.setAdjustInflation("nominal")}
        />
        <label htmlFor="radioNominal">Nominal</label>
      </div>
    </div>
  );
};

export default InflationSwitch;
